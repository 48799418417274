// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-banner {
  border-radius: var(--gb-border-radius);
  margin: 0 var(--half-ion-margin);
}
.confirmation-banner ion-label {
  font-size: var(--medium-text-size);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .top-buttons {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIml0ZW0tZGV0YWlscy1jYXJkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usc0NBQUE7RUFDQSxnQ0FBQTtBQUNGO0FBQ0U7RUFDRSxrQ0FBQTtBQUNKOztBQUdBO0VBQ0UsYUFBQTtFQUNBLDhCQUFBO0VBQ0EsbUJBQUE7QUFBRjtBQUVFO0VBQ0UsV0FBQTtFQUNBLGFBQUE7RUFDQSxxQkFBQTtFQUNBLHNCQUFBO0FBQUoiLCJmaWxlIjoiaXRlbS1kZXRhaWxzLWNhcmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY29uZmlybWF0aW9uLWJhbm5lciB7XG4gIGJvcmRlci1yYWRpdXM6IHZhcigtLWdiLWJvcmRlci1yYWRpdXMpO1xuICBtYXJnaW46IDAgdmFyKC0taGFsZi1pb24tbWFyZ2luKTtcblxuICBpb24tbGFiZWwge1xuICAgIGZvbnQtc2l6ZTogdmFyKC0tbWVkaXVtLXRleHQtc2l6ZSk7XG4gIH1cbn1cblxuLmhlYWRlciB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcblxuICAudG9wLWJ1dHRvbnMge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGZsZXgtZW5kO1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/reservation-details/item-details-card/item-details-card.component.scss"],"names":[],"mappings":"AAAA;EACE,sCAAA;EACA,gCAAA;AACF;AACE;EACE,kCAAA;AACJ;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAAF;AAEE;EACE,WAAA;EACA,aAAA;EACA,qBAAA;EACA,sBAAA;AAAJ;AACA,g8BAAg8B","sourcesContent":[".confirmation-banner {\n  border-radius: var(--gb-border-radius);\n  margin: 0 var(--half-ion-margin);\n\n  ion-label {\n    font-size: var(--medium-text-size);\n  }\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  .top-buttons {\n    width: 100%;\n    display: flex;\n    align-items: flex-end;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

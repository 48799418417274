export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A clone of the `Upload` scalar type represents a file upload. */
  GbUpload: any;
  _FieldSet: any;
};

/** Expert availability frequency */
export enum AvailabilityFrequency {
  OneTime = 'one_time',
  Weekly = 'weekly'
}

export type BreakTimeSlot = ITimeSlot & {
  __typename?: 'BreakTimeSlot';
  break?: Maybe<ProviderBreak>;
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset?: Maybe<Scalars['Int']>;
  /** End time in epoch seconds */
  end_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset?: Maybe<Scalars['Int']>;
  /** Start time in epoch seconds */
  start_time?: Maybe<Scalars['Int']>;
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id?: Maybe<Scalars['String']>;
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: Maybe<Scalars['String']>;
  type?: Maybe<TimeSlotType>;
};

export type CancellationPolicy = {
  __typename?: 'CancellationPolicy';
  /** The number of minutes before or after the reservation start time when the cancellation fee will be charged. If the value is negative, the fee will be charged before the reservation start time. If the value is positive, the fee will be charged after the reservation start time.  */
  charge_time_offset_min?: Maybe<Scalars['Int']>;
  /** The fixed amount to charge as a cancellation fee. The value is in cents. For example, 100 is $1.00. This value takes precedent over the percentage value. */
  fixed_amount?: Maybe<Scalars['Int']>;
  /** The percentage of the total amount to charge as a cancellation fee. The decimal value must be between 0 and 1. For example, 0.1 is 10% and 0.5 is 50%. */
  percent?: Maybe<Scalars['Float']>;
};

export type CancellationPolicyInput = {
  /** The number of minutes before or after the reservation start time when the cancellation fee will be charged. If the value is negative, the fee will be charged before the reservation start time. If the value is positive, the fee will be charged after the reservation start time.  */
  charge_time_offset_min?: InputMaybe<Scalars['Int']>;
  /** The fixed amount to charge as a cancellation fee. The value is in cents. For example, 100 is $1.00. This value takes precedent over the percentage value. */
  fixed_amount?: InputMaybe<Scalars['Int']>;
  /** The percentage of the total amount to charge as a cancellation fee. The decimal value must be between 0 and 1. For example, 0.1 is 10% and 0.5 is 50%. */
  percent?: InputMaybe<Scalars['Float']>;
};

export type CardPaymentMethod = {
  __typename?: 'CardPaymentMethod';
  brand?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type ConfirmDeleteAccountInput = {
  code?: InputMaybe<Scalars['String']>;
};

export type ConfirmDeleteAccountResponse = {
  __typename?: 'ConfirmDeleteAccountResponse';
  confirmed: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type ConfirmPhoneNumberInput = {
  code: Scalars['String'];
  phone_number: Scalars['String'];
};

export type Coupon = {
  __typename?: 'Coupon';
  active?: Maybe<Scalars['Boolean']>;
  amount_off?: Maybe<Scalars['Int']>;
  auto_apply?: Maybe<Scalars['Boolean']>;
  display_properties?: Maybe<CouponDisplayProperties>;
  /** Specifies how long the discount will be in effect. Can be FOREVER, ONCE, or REPEATING */
  duration?: Maybe<CouponDuration>;
  /** Number of months the coupon is valid for. Required if duration is REPEATING */
  duration_in_months?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  percent_off?: Maybe<Scalars['Float']>;
  restrictions?: Maybe<CouponRestrictions>;
  times_redeemed?: Maybe<Scalars['Int']>;
};

export type CouponDisplayProperties = {
  __typename?: 'CouponDisplayProperties';
  description?: Maybe<Scalars['String']>;
  display_location?: Maybe<PromotionCodeDisplayLocation>;
  display_order?: Maybe<Scalars['Float']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Duration of the coupon */
export enum CouponDuration {
  Forever = 'FOREVER',
  Once = 'ONCE',
  Repeating = 'REPEATING'
}

export type CouponRestrictions = {
  __typename?: 'CouponRestrictions';
  apply_to_customer?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['Int']>;
  first_time_transaction?: Maybe<Scalars['Boolean']>;
  max_redemptions?: Maybe<Scalars['Int']>;
  min_vehicle_count?: Maybe<Scalars['Int']>;
  minimum_amount?: Maybe<Scalars['Int']>;
  minimum_amount_currency?: Maybe<Scalars['String']>;
};

export type Discount = {
  __typename?: 'Discount';
  auto_applied?: Maybe<Scalars['Boolean']>;
  coupon?: Maybe<Coupon>;
  id: Scalars['ID'];
  promotion_code?: Maybe<PromotionCode>;
};

export type DiscountAmount = {
  __typename?: 'DiscountAmount';
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Discount>;
  id: Scalars['ID'];
};

export type DiscountApplyInput = {
  coupon_id?: InputMaybe<Scalars['String']>;
  promotion_code?: InputMaybe<Scalars['String']>;
};

export type DiscountSelection = {
  __typename?: 'DiscountSelection';
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type ExpertProviderServicesFilters = {
  active?: InputMaybe<Scalars['Boolean']>;
  is_add_on?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  service_category?: InputMaybe<ProviderServiceCategory>;
};

export type ExpertReservationConfirmItemsInput = {
  changes?: InputMaybe<Array<ReservationChangeInput>>;
  force_time_conflict?: InputMaybe<Scalars['Boolean']>;
};

export type ExpertReservationRescheduleInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  new_arrival_time: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
};

export type Fleet = {
  __typename?: 'Fleet';
  discount_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  owner_uid: Scalars['String'];
};

export type FutureTimeSlotInput = {
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset: Scalars['Int'];
  /** End time in epoch seconds */
  end_time: Scalars['Int'];
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset: Scalars['Int'];
  /** Start time in epoch seconds */
  start_time: Scalars['Int'];
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id: Scalars['String'];
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: InputMaybe<Scalars['String']>;
};

export type GeoLocationInput = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type IProviderService = {
  active?: Maybe<Scalars['Boolean']>;
  add_on?: Maybe<ProviderServiceAddOn>;
  add_ons?: Maybe<Array<ProviderServiceAddOn>>;
  created_at?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['String']>;
  disabled_at?: Maybe<Scalars['Float']>;
  displayProperties?: Maybe<IProviderServiceDisplayProperties>;
  id: Scalars['ID'];
  input_requirements?: Maybe<Array<InputRequirements>>;
  is_add_on?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<IProviderServiceItem>>;
  options?: Maybe<Array<IProviderServiceOption>>;
  provider_id?: Maybe<Scalars['String']>;
  service_category: ProviderServiceCategory;
  service_type?: Maybe<ProviderServiceType>;
};


export type IProviderServiceAdd_OnArgs = {
  id: Scalars['String'];
};


export type IProviderServiceOptionsArgs = {
  filters?: InputMaybe<ProviderServiceOptionsFilters>;
};

export type IProviderServiceDisplayProperties = {
  additional_info?: Maybe<Scalars['String']>;
  /** Whether to show a none option for the service during customer selection */
  allow_none_option?: Maybe<Scalars['Boolean']>;
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  /** Whether or not the user is required to select an option. This will only be honored if the service has more than one option. */
  option_selection_required?: Maybe<Scalars['Boolean']>;
  prompt?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  /** The starting price in cents */
  starting_price?: Maybe<Scalars['Int']>;
  sub_category?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IProviderServiceItem = {
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  included_comment?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  service_category?: Maybe<ProviderServiceCategory>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IProviderServiceOption = {
  active?: Maybe<Scalars['Boolean']>;
  disabled_at?: Maybe<Scalars['Float']>;
  duration_min?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  /** The price in cents */
  price?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['String']>;
  provider_service_id?: Maybe<Scalars['String']>;
  /** The required size of the vehicle for this option. Applies to vehicle services only. */
  vehicle_size?: Maybe<VehicleSize>;
};

export type IReservationItem = {
  actions?: Maybe<Array<ReservationAction>>;
  completed_at?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  duration_min?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  in_progress_end_time?: Maybe<Scalars['Int']>;
  in_progress_start_time?: Maybe<Scalars['Int']>;
  is_preview?: Maybe<Scalars['Boolean']>;
  provider_services?: Maybe<Array<ProviderServiceSelection>>;
  status?: Maybe<ReservationItemStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  vehicle?: Maybe<Vehicle>;
};

export type ITimeSlot = {
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset?: Maybe<Scalars['Int']>;
  /** End time in epoch seconds */
  end_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset?: Maybe<Scalars['Int']>;
  /** Start time in epoch seconds */
  start_time?: Maybe<Scalars['Int']>;
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id?: Maybe<Scalars['String']>;
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: Maybe<Scalars['String']>;
  type?: Maybe<TimeSlotType>;
};

export type InitiateDeleteAccountInput = {
  channel?: InputMaybe<VerificationChannel>;
};

export type InitiateDeleteAccountResponse = {
  __typename?: 'InitiateDeleteAccountResponse';
  channel?: Maybe<VerificationChannel>;
  message: Scalars['String'];
};

/** The type of input requirement */
export enum InputRequirementType {
  Location = 'LOCATION',
  TimeSlot = 'TIME_SLOT',
  Vehicle = 'VEHICLE'
}

export type InputRequirements = {
  __typename?: 'InputRequirements';
  field_name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<InputRequirementType>;
  validation?: Maybe<Array<InputRequirementsValidation>>;
};

export type InputRequirementsValidation = {
  __typename?: 'InputRequirementsValidation';
  error_message?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amount_paid?: Maybe<Scalars['Int']>;
  amount_remaining?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_preview?: Maybe<Scalars['Boolean']>;
  lines?: Maybe<Array<InvoiceItem>>;
  misc_lines?: Maybe<Array<InvoiceMiscItem>>;
  scheduled_payments?: Maybe<Array<ScheduledPayment>>;
  status?: Maybe<InvoiceStatus>;
  /** Total of all subscriptions, invoice items, and prorations on the invoice before any invoice level discount or tax is applied. Item discounts are already incorporated. value in cents */
  subtotal?: Maybe<Scalars['Int']>;
  /** value in cents */
  tax?: Maybe<Scalars['Int']>;
  tax_percent?: Maybe<Scalars['Float']>;
  /** value in cents */
  total?: Maybe<Scalars['Int']>;
  total_discount_amounts?: Maybe<Array<DiscountAmount>>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type InvoiceMiscItem = {
  __typename?: 'InvoiceMiscItem';
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  type?: Maybe<MiscellaneousItemType>;
};

/** Status of the invoice */
export enum InvoiceStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

/** The level of the message */
export enum MessageLevel {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING'
}

export type MilitaryTimeSlot = {
  __typename?: 'MilitaryTimeSlot';
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset?: Maybe<Scalars['Int']>;
  end_time_military?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset?: Maybe<Scalars['Int']>;
  start_time_military?: Maybe<Scalars['String']>;
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id?: Maybe<Scalars['String']>;
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: Maybe<Scalars['String']>;
};

export type MilitaryTimeSlotInput = {
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset: Scalars['Int'];
  /** End time in military time format (HH:mm) */
  end_time_military: Scalars['String'];
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset: Scalars['Int'];
  /** Start time in military time format (HH:mm) */
  start_time_military: Scalars['String'];
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id: Scalars['String'];
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: InputMaybe<Scalars['String']>;
};

/** Miscellaneous item type */
export enum MiscellaneousItemType {
  CancellationFee = 'CANCELLATION_FEE',
  Gratuity = 'GRATUITY',
  Other = 'OTHER'
}

export type Mutation = {
  __typename?: 'Mutation';
  providerAvailabilityCreate?: Maybe<ProviderAvailability>;
  providerAvailabilityDelete?: Maybe<ProviderAvailabilityDeletePayload>;
  providerAvailabilitySeriesCreate?: Maybe<ProviderAvailabilitySeries>;
  providerAvailabilitySeriesDelete?: Maybe<ProviderAvailabilitySeriesDeletePayload>;
  providerAvailabilitySeriesUpdate?: Maybe<ProviderAvailabilitySeries>;
  providerAvailabilityUpdate?: Maybe<ProviderAvailability>;
  providerSave?: Maybe<Provider>;
  providerServiceAddAddOn?: Maybe<IProviderService>;
  providerServiceAddItem?: Maybe<IProviderService>;
  providerServiceCreate?: Maybe<IProviderService>;
  providerServiceItemCreate?: Maybe<IProviderServiceItem>;
  providerServiceItemUpdate?: Maybe<IProviderServiceItem>;
  providerServiceOptionCreate?: Maybe<IProviderServiceOption>;
  providerServiceOptionUpdate?: Maybe<IProviderServiceOption>;
  providerServiceRemoveAddOn?: Maybe<IProviderService>;
  providerServiceRemoveItem?: Maybe<IProviderService>;
  providerServiceUpdate?: Maybe<IProviderService>;
  reservationApplyDiscount?: Maybe<Reservation>;
  reservationCompleteItem?: Maybe<Reservation>;
  reservationConfirm?: Maybe<Reservation>;
  reservationConfirmItems?: Maybe<ReservationConfirmResponse>;
  reservationCreate?: Maybe<Reservation>;
  reservationDecline?: Maybe<Reservation>;
  reservationItemCreate?: Maybe<Reservation>;
  reservationItemDelete?: Maybe<Reservation>;
  reservationItemUpdate?: Maybe<Reservation>;
  reservationReschedule?: Maybe<Reservation>;
  reservationSchedule?: Maybe<ReservationScheduleResponse>;
  reservationStartItem?: Maybe<Reservation>;
  reservationStartRoute?: Maybe<Reservation>;
  reservationUpdate?: Maybe<Reservation>;
  reservationUpdateEstimatedCompletion?: Maybe<Reservation>;
  userConfirmDeleteAccount: ConfirmDeleteAccountResponse;
  userConfirmPhoneNumberUpdate: MutationResponse;
  userInitiateDeleteAccount: InitiateDeleteAccountResponse;
  userRequestPhoneNumberUpdate: PhoneNumberVerificationResponse;
};


export type MutationProviderAvailabilityCreateArgs = {
  input: ProviderAvailabilityCreateInput;
};


export type MutationProviderAvailabilityDeleteArgs = {
  id: Scalars['String'];
};


export type MutationProviderAvailabilitySeriesCreateArgs = {
  input: ProviderAvailabilitySeriesCreateInput;
};


export type MutationProviderAvailabilitySeriesDeleteArgs = {
  id: Scalars['String'];
};


export type MutationProviderAvailabilitySeriesUpdateArgs = {
  id: Scalars['String'];
  input: ProviderAvailabilitySeriesUpdateInput;
};


export type MutationProviderAvailabilityUpdateArgs = {
  id: Scalars['String'];
  input: ProviderAvailabilityUpdateInput;
};


export type MutationProviderSaveArgs = {
  input: SaveProviderInput;
};


export type MutationProviderServiceAddAddOnArgs = {
  id: Scalars['String'];
  input: ProviderServiceModifyAddOnsInput;
};


export type MutationProviderServiceAddItemArgs = {
  id: Scalars['String'];
  itemId: Scalars['String'];
};


export type MutationProviderServiceCreateArgs = {
  displayProperties?: InputMaybe<ProviderServiceDisplayPropertiesCreateInput>;
  service: ProviderServiceCreateInput;
};


export type MutationProviderServiceItemCreateArgs = {
  item: ProviderServiceItemCreateInput;
};


export type MutationProviderServiceItemUpdateArgs = {
  id: Scalars['String'];
  item: ProviderServiceItemUpdateInput;
};


export type MutationProviderServiceOptionCreateArgs = {
  option: ProviderServiceOptionCreateInput;
};


export type MutationProviderServiceOptionUpdateArgs = {
  id: Scalars['String'];
  option: ProviderServiceOptionUpdateInput;
};


export type MutationProviderServiceRemoveAddOnArgs = {
  addOnId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationProviderServiceRemoveItemArgs = {
  id: Scalars['String'];
  itemId: Scalars['String'];
};


export type MutationProviderServiceUpdateArgs = {
  displayProperties?: InputMaybe<ProviderServiceDisplayPropertiesUpdateInput>;
  id: Scalars['String'];
  service?: InputMaybe<ProviderServiceUpdateInput>;
};


export type MutationReservationApplyDiscountArgs = {
  id: Scalars['String'];
  input: DiscountApplyInput;
};


export type MutationReservationCompleteItemArgs = {
  id: Scalars['String'];
  input: ReservationCompleteItemInput;
};


export type MutationReservationConfirmArgs = {
  id: Scalars['String'];
};


export type MutationReservationConfirmItemsArgs = {
  id: Scalars['String'];
  input?: InputMaybe<ExpertReservationConfirmItemsInput>;
};


export type MutationReservationCreateArgs = {
  input: ReservationCreateInput;
};


export type MutationReservationDeclineArgs = {
  id: Scalars['String'];
  input?: InputMaybe<ReservationDeclineInput>;
};


export type MutationReservationItemCreateArgs = {
  id: Scalars['String'];
  input: ReservationItemSelectionInput;
};


export type MutationReservationItemDeleteArgs = {
  id: Scalars['String'];
  itemId: Scalars['String'];
};


export type MutationReservationItemUpdateArgs = {
  id: Scalars['String'];
  input: ReservationItemSelectionInput;
  itemId: Scalars['String'];
};


export type MutationReservationRescheduleArgs = {
  id: Scalars['String'];
  input: ExpertReservationRescheduleInput;
};


export type MutationReservationScheduleArgs = {
  id: Scalars['String'];
  input: ReservationScheduleInput;
};


export type MutationReservationStartItemArgs = {
  id: Scalars['String'];
  input: ReservationStartItemInput;
};


export type MutationReservationStartRouteArgs = {
  id: Scalars['String'];
};


export type MutationReservationUpdateArgs = {
  id: Scalars['String'];
  input: ReservationUpdateInput;
};


export type MutationReservationUpdateEstimatedCompletionArgs = {
  id: Scalars['String'];
  input: ReservationUpdateEstimatedCompletionInput;
};


export type MutationUserConfirmDeleteAccountArgs = {
  data: ConfirmDeleteAccountInput;
};


export type MutationUserConfirmPhoneNumberUpdateArgs = {
  input: ConfirmPhoneNumberInput;
};


export type MutationUserInitiateDeleteAccountArgs = {
  data: InitiateDeleteAccountInput;
};


export type MutationUserRequestPhoneNumberUpdateArgs = {
  input: UpdatePhoneNumberInput;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PaymentChargePolicy = {
  __typename?: 'PaymentChargePolicy';
  /** The payment methods that are accepted by your business. If this is not set, all eligible payment methods will be accepted. */
  accepted_payment_methods?: Maybe<Array<PaymentMethodType>>;
  /** The status of the reservation when the remaining amount will be charged or is due. If this is not set, the remaining amount will be charged or is due when the reservation is completed. */
  remaining_amount_when_status?: Maybe<ReservationStatus>;
  /** The amount to charge as a pre-charge. If this is set with pre_charge_percent, the higher of the two values will be used. If this value is greater than the total amount due, the total amount will be used. The value is in cents. For example, 100 is $1.00. */
  upfront_charge_amount?: Maybe<Scalars['Float']>;
  /** The percentage of the total amount to charge as a pre-charge. The decimal value must be between 0 and 1. For example, 0.1 is 10% and 0.5 is 50%. */
  upfront_charge_percent?: Maybe<Scalars['Float']>;
};

export type PaymentChargePolicyInput = {
  /** The payment methods that are accepted by your business. If this is not set, all eligible payment methods will be accepted. */
  accepted_payment_methods?: InputMaybe<Array<PaymentMethodType>>;
  /** The status of the reservation when the remaining amount will be charged or is due. If this is not set, the remaining amount will be charged or is due when the reservation is completed. */
  remaining_amount_when_status?: InputMaybe<ReservationStatus>;
  /** The amount to charge as a pre-charge. If this is set with pre_charge_percent, the higher of the two values will be used. If this value is greater than the total amount due, the total amount will be used. The value is in cents. For example, 100 is $1.00. */
  upfront_charge_amount?: InputMaybe<Scalars['Float']>;
  /** The percentage of the total amount to charge as a pre-charge. The decimal value must be between 0 and 1. For example, 0.1 is 10% and 0.5 is 50%. */
  upfront_charge_percent?: InputMaybe<Scalars['Float']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  card?: Maybe<CardPaymentMethod>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentMethodType>;
};

/** The type of payment method */
export enum PaymentMethodType {
  Card = 'CARD',
  Cash = 'CASH'
}

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  amount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  payment_method_type?: Maybe<PaymentMethodType>;
  status?: Maybe<PaymentTransactionStatus>;
  transaction_type?: Maybe<PaymentTransactionType>;
};

/** The status of the payment transaction */
export enum PaymentTransactionStatus {
  Finalized = 'FINALIZED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED'
}

/** The type of the payment transaction */
export enum PaymentTransactionType {
  Charge = 'CHARGE',
  Refund = 'REFUND',
  Transfer = 'TRANSFER',
  TransferReversal = 'TRANSFER_REVERSAL'
}

export type PhoneNumberVerificationResponse = {
  __typename?: 'PhoneNumberVerificationResponse';
  channel?: Maybe<VerificationChannel>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PromotionCode = {
  __typename?: 'PromotionCode';
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  coupon?: Maybe<Coupon>;
  id: Scalars['ID'];
  restrictions?: Maybe<PromotionCodeRestrictions>;
  times_redeemed?: Maybe<Scalars['Int']>;
};

/** Location where the promotion code will be displayed */
export enum PromotionCodeDisplayLocation {
  HomePage = 'HOME_PAGE',
  None = 'NONE'
}

export type PromotionCodeRestrictions = {
  __typename?: 'PromotionCodeRestrictions';
  apply_to_customer?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['Int']>;
  first_time_transaction?: Maybe<Scalars['Boolean']>;
  max_redemptions?: Maybe<Scalars['Int']>;
  min_vehicle_count?: Maybe<Scalars['Int']>;
  minimum_amount?: Maybe<Scalars['Int']>;
  minimum_amount_currency?: Maybe<Scalars['String']>;
};

export type Provider = {
  __typename?: 'Provider';
  business_settings?: Maybe<ProviderBusinessSettings>;
  id: Scalars['ID'];
  policies?: Maybe<ProviderPolicies>;
  pricing?: Maybe<ProviderPricing>;
  public_profile?: Maybe<ProviderPublicProfile>;
  stripe_info?: Maybe<ProviderStripeInfo>;
};

export type ProviderAccountSession = {
  __typename?: 'ProviderAccountSession';
  client_secret?: Maybe<Scalars['String']>;
};

export type ProviderAvailability = {
  __typename?: 'ProviderAvailability';
  available_time_slots?: Maybe<Array<TimeSlot>>;
  /** @deprecated Use series.frequency instead */
  frequency?: Maybe<AvailabilityFrequency>;
  id: Scalars['ID'];
  is_stationary?: Maybe<Scalars['Boolean']>;
  location?: Maybe<ProviderLocation>;
  /** Whether the availability can be reserved by any customer or only the provider's customers */
  public?: Maybe<Scalars['Boolean']>;
  reservation_window?: Maybe<TimeSlot>;
  series?: Maybe<ProviderAvailabilitySeries>;
  time_slots?: Maybe<Array<ITimeSlot>>;
};


export type ProviderAvailabilityAvailable_Time_SlotsArgs = {
  filters?: InputMaybe<ProviderAvailabilityTimeSlotsFilter>;
};

export type ProviderAvailabilityCreateInput = {
  /** @deprecated Use time_slot instead */
  end_time?: InputMaybe<Scalars['Int']>;
  /** @deprecated Frequency is deprecated created a provider availability series instead */
  frequency?: InputMaybe<AvailabilityFrequency>;
  is_stationary?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<ProviderLocationInput>;
  /** Whether the availability can be reserved by any customer or only the provider's customers */
  public?: InputMaybe<Scalars['Boolean']>;
  reservation_window?: InputMaybe<FutureTimeSlotInput>;
  /** @deprecated Use time_slot instead */
  start_time?: InputMaybe<Scalars['Int']>;
};

export type ProviderAvailabilityDeletePayload = {
  __typename?: 'ProviderAvailabilityDeletePayload';
  provider_availability_id?: Maybe<Scalars['String']>;
};

export type ProviderAvailabilitySeries = {
  __typename?: 'ProviderAvailabilitySeries';
  frequency?: Maybe<AvailabilityFrequency>;
  id: Scalars['ID'];
  is_stationary?: Maybe<Scalars['Boolean']>;
  location?: Maybe<ProviderLocation>;
  /** Whether the availability can be reserved by any customer or only the provider's customers */
  public?: Maybe<Scalars['Boolean']>;
  /** An array of integers where each value is an ISO weekday number between 1 and 7, where 1 is Monday */
  recurring_days?: Maybe<Array<Scalars['Int']>>;
  reservation_window?: Maybe<MilitaryTimeSlot>;
};

export type ProviderAvailabilitySeriesCreateInput = {
  location?: InputMaybe<ProviderLocationInput>;
  /** Whether the availability can be reserved by any customer or only the provider's customers */
  public?: InputMaybe<Scalars['Boolean']>;
  /** An array of integers where each value is an ISO weekday number between 1 and 7, where 1 is Monday */
  recurring_days: Array<Scalars['Int']>;
  reservation_window: MilitaryTimeSlotInput;
};

export type ProviderAvailabilitySeriesDeletePayload = {
  __typename?: 'ProviderAvailabilitySeriesDeletePayload';
  provider_availability_series_id?: Maybe<Scalars['String']>;
};

export type ProviderAvailabilitySeriesUpdateInput = {
  location?: InputMaybe<ProviderLocationInput>;
  /** Whether the availability can be reserved by any customer or only the provider's customers */
  public?: InputMaybe<Scalars['Boolean']>;
  /** An array of integers where each value is an ISO weekday number between 1 and 7, where 1 is Monday */
  recurring_days?: InputMaybe<Array<Scalars['Int']>>;
  reservation_window?: InputMaybe<MilitaryTimeSlotInput>;
};

export type ProviderAvailabilityTimeSlotsFilter = {
  duration_min: Scalars['Int'];
};

export type ProviderAvailabilityUpdateInput = {
  is_stationary?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<ProviderLocationInput>;
  /** Whether the availability can be reserved by any customer or only the provider's customers */
  public?: InputMaybe<Scalars['Boolean']>;
  reservation_window?: InputMaybe<FutureTimeSlotInput>;
};

export type ProviderBreak = {
  __typename?: 'ProviderBreak';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ProviderBusinessSettings = {
  __typename?: 'ProviderBusinessSettings';
  /** An ISO 3166-1 alpha-2 country code */
  country?: Maybe<Scalars['String']>;
  /** The category of the services offered */
  services_category?: Maybe<ProviderServiceCategory>;
};

export type ProviderBusinessSettingsInput = {
  /** An ISO 3166-1 alpha-2 country code */
  country?: InputMaybe<Scalars['String']>;
  /** The category of the services offered */
  services_category?: InputMaybe<ProviderServiceCategory>;
};

export type ProviderLocale = {
  __typename?: 'ProviderLocale';
  currency?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type ProviderLocaleInput = {
  currency?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type ProviderLocation = {
  __typename?: 'ProviderLocation';
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  radius_meters?: Maybe<Scalars['Float']>;
};

export type ProviderLocationInput = {
  address?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  radius_meters?: InputMaybe<Scalars['Float']>;
};

export type ProviderPaymentAccount = {
  __typename?: 'ProviderPaymentAccount';
  charges_enabled?: Maybe<Scalars['Boolean']>;
  details_submitted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  payouts_enabled?: Maybe<Scalars['Boolean']>;
  requirements?: Maybe<ProviderPaymentAccountRequirements>;
};

export type ProviderPaymentAccountRequirements = {
  __typename?: 'ProviderPaymentAccountRequirements';
  currently_due?: Maybe<Array<Scalars['String']>>;
  eventually_due?: Maybe<Array<Scalars['String']>>;
  past_due?: Maybe<Array<Scalars['String']>>;
};

export type ProviderPolicies = {
  __typename?: 'ProviderPolicies';
  /** The list of cancellation policies for the provider. The first policy to match the cancellation time will be used. */
  cancellation_policies?: Maybe<Array<CancellationPolicy>>;
  payment_charge_policy?: Maybe<PaymentChargePolicy>;
  reservation_requirements?: Maybe<ProviderReservationRequirements>;
};

export type ProviderPoliciesInput = {
  /** The list of cancellation policies for the provider. The first policy to match the cancellation time will be used. */
  cancellation_policies?: InputMaybe<Array<CancellationPolicyInput>>;
  payment_charge_policy?: InputMaybe<PaymentChargePolicyInput>;
  reservation_requirements?: InputMaybe<ProviderReservationRequirementsInput>;
};

export type ProviderPricing = {
  __typename?: 'ProviderPricing';
  monthly_fee?: Maybe<Scalars['Float']>;
  transaction_fee_pricing?: Maybe<ProviderTransactionFeePricing>;
};

export type ProviderPublicProfile = {
  __typename?: 'ProviderPublicProfile';
  banner_image_url?: Maybe<Scalars['String']>;
  display_bio?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  locale?: Maybe<ProviderLocale>;
  logo_image_url?: Maybe<Scalars['String']>;
  personal_image_url?: Maybe<Scalars['String']>;
  show_display_name_by_logo?: Maybe<Scalars['Boolean']>;
  show_services_comparison?: Maybe<Scalars['Boolean']>;
  url_slug?: Maybe<Scalars['String']>;
};

export type ProviderPublicProfileInput = {
  banner_image?: InputMaybe<Scalars['GbUpload']>;
  display_bio?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<ProviderLocaleInput>;
  logo_image?: InputMaybe<Scalars['GbUpload']>;
  personal_image?: InputMaybe<Scalars['GbUpload']>;
  show_display_name_by_logo?: InputMaybe<Scalars['Boolean']>;
  show_services_comparison?: InputMaybe<Scalars['Boolean']>;
  url_slug?: InputMaybe<Scalars['String']>;
};

export type ProviderReservationPayment = {
  __typename?: 'ProviderReservationPayment';
  currency?: Maybe<Scalars['String']>;
  expert_amount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
};

export type ProviderReservationRequirements = {
  __typename?: 'ProviderReservationRequirements';
  requires_access_to_power?: Maybe<Scalars['Boolean']>;
  requires_access_to_water?: Maybe<Scalars['Boolean']>;
};

export type ProviderReservationRequirementsInput = {
  requires_access_to_power?: InputMaybe<Scalars['Boolean']>;
  requires_access_to_water?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderScheduleFilters = {
  window_end_time: Scalars['Int'];
  window_start_time: Scalars['Int'];
};

export type ProviderService = IProviderService & {
  __typename?: 'ProviderService';
  active?: Maybe<Scalars['Boolean']>;
  add_on?: Maybe<ProviderServiceAddOn>;
  add_ons?: Maybe<Array<ProviderServiceAddOn>>;
  created_at?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['String']>;
  disabled_at?: Maybe<Scalars['Float']>;
  displayProperties?: Maybe<IProviderServiceDisplayProperties>;
  id: Scalars['ID'];
  input_requirements?: Maybe<Array<InputRequirements>>;
  is_add_on?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<IProviderServiceItem>>;
  options?: Maybe<Array<IProviderServiceOption>>;
  provider_id?: Maybe<Scalars['String']>;
  service_category: ProviderServiceCategory;
  service_type?: Maybe<ProviderServiceType>;
};


export type ProviderServiceAdd_OnArgs = {
  id: Scalars['String'];
};


export type ProviderServiceOptionsArgs = {
  filters?: InputMaybe<ProviderServiceOptionsFilters>;
};

export type ProviderServiceAddOn = {
  __typename?: 'ProviderServiceAddOn';
  id: Scalars['ID'];
  included?: Maybe<Scalars['Boolean']>;
  included_options?: Maybe<Array<IProviderServiceOption>>;
  service?: Maybe<IProviderService>;
};

/** Category type for provider service */
export enum ProviderServiceCategory {
  MaidServices = 'MAID_SERVICES',
  VehicleWash = 'VEHICLE_WASH'
}

export type ProviderServiceCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  is_add_on?: InputMaybe<Scalars['Boolean']>;
  service_category: ProviderServiceCategory;
  service_type?: InputMaybe<ProviderServiceType>;
  taxable?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderServiceDisplayProperties = IProviderServiceDisplayProperties & {
  __typename?: 'ProviderServiceDisplayProperties';
  additional_info?: Maybe<Scalars['String']>;
  /** Whether to show a none option for the service during customer selection */
  allow_none_option?: Maybe<Scalars['Boolean']>;
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  /** Whether or not the user is required to select an option. This will only be honored if the service has more than one option. */
  option_selection_required?: Maybe<Scalars['Boolean']>;
  prompt?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  /** The starting price in cents */
  starting_price?: Maybe<Scalars['Int']>;
  sub_category?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProviderServiceDisplayPropertiesCreateInput = {
  additional_info?: InputMaybe<Scalars['String']>;
  /** Whether to show a none option for the service during customer selection */
  allow_none_option?: InputMaybe<Scalars['Boolean']>;
  display_order?: InputMaybe<Scalars['Float']>;
  icon?: InputMaybe<Scalars['String']>;
  /** Whether or not the user is required to select an option. This will only be honored if the service has more than one option. */
  option_selection_required?: InputMaybe<Scalars['Boolean']>;
  prompt?: InputMaybe<Scalars['String']>;
  recommended?: InputMaybe<Scalars['Boolean']>;
  sub_category?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  theme_color?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ProviderServiceDisplayPropertiesUpdateInput = {
  additional_info?: InputMaybe<Scalars['String']>;
  /** Whether to show a none option for the service during customer selection */
  allow_none_option?: InputMaybe<Scalars['Boolean']>;
  display_order?: InputMaybe<Scalars['Float']>;
  icon?: InputMaybe<Scalars['String']>;
  /** Whether or not the user is required to select an option. This will only be honored if the service has more than one option. */
  option_selection_required?: InputMaybe<Scalars['Boolean']>;
  prompt?: InputMaybe<Scalars['String']>;
  recommended?: InputMaybe<Scalars['Boolean']>;
  sub_category?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  theme_color?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ProviderServiceItem = IProviderServiceItem & {
  __typename?: 'ProviderServiceItem';
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  included_comment?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  service_category?: Maybe<ProviderServiceCategory>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProviderServiceItemCreateInput = {
  display_order?: InputMaybe<Scalars['Float']>;
  icon?: InputMaybe<Scalars['String']>;
  included_comment?: InputMaybe<Scalars['String']>;
  service_category: ProviderServiceCategory;
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ProviderServiceItemFilters = {
  service_category: ProviderServiceCategory;
};

export type ProviderServiceItemUpdateInput = {
  display_order?: InputMaybe<Scalars['Float']>;
  icon?: InputMaybe<Scalars['String']>;
  included_comment?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ProviderServiceModifyAddOnsInput = {
  included?: InputMaybe<Scalars['Boolean']>;
  included_option_ids?: InputMaybe<Array<Scalars['String']>>;
  service_id: Scalars['String'];
};

export type ProviderServiceOption = IProviderServiceOption & {
  __typename?: 'ProviderServiceOption';
  active?: Maybe<Scalars['Boolean']>;
  disabled_at?: Maybe<Scalars['Float']>;
  duration_min?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  /** The price in cents */
  price?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['String']>;
  provider_service_id?: Maybe<Scalars['String']>;
  /** The required size of the vehicle for this option. Applies to vehicle services only. */
  vehicle_size?: Maybe<VehicleSize>;
};

export type ProviderServiceOptionCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  duration_min?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  /** The price in cents */
  price: Scalars['Int'];
  provider_service_id: Scalars['String'];
  /** The required size of the vehicle for this option. Applies to vehicle services only. */
  vehicle_size?: InputMaybe<VehicleSize>;
};

export type ProviderServiceOptionUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  duration_min?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  /** The price in cents */
  price?: InputMaybe<Scalars['Int']>;
  /** The required size of the vehicle for this option. Applies to vehicle services only. */
  vehicle_size?: InputMaybe<VehicleSize>;
};

export type ProviderServiceOptionsFilters = {
  /** whether to include inactive options. can be used for experts and operators */
  active?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderServiceSelection = {
  __typename?: 'ProviderServiceSelection';
  duration_min?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  option?: Maybe<ProviderServiceOption>;
  price?: Maybe<Scalars['Float']>;
  service?: Maybe<ProviderService>;
};

export type ProviderServiceSelectionInput = {
  option_id: Scalars['String'];
  service_id: Scalars['String'];
};

/** Type of service */
export enum ProviderServiceType {
  Mobile = 'MOBILE'
}

export type ProviderServiceUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  service_type?: InputMaybe<ProviderServiceType>;
  taxable?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderStripeInfo = {
  __typename?: 'ProviderStripeInfo';
  account?: Maybe<ProviderPaymentAccount>;
  account_session?: Maybe<ProviderAccountSession>;
};

export type ProviderTransactionFeePricing = {
  __typename?: 'ProviderTransactionFeePricing';
  new_customer_lead_fee_percent?: Maybe<Scalars['Float']>;
  transaction_fee_base?: Maybe<Scalars['Float']>;
  transaction_fee_percent?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<User>;
  provider?: Maybe<Provider>;
  providerAvailabilities?: Maybe<Array<ProviderAvailability>>;
  providerBySlug?: Maybe<Provider>;
  providerService?: Maybe<IProviderService>;
  providerServiceItems?: Maybe<Array<IProviderServiceItem>>;
  providerServices?: Maybe<Array<IProviderService>>;
  reservation?: Maybe<Reservation>;
  reservationGetByChannel?: Maybe<Reservation>;
  reservations?: Maybe<Array<Reservation>>;
  schedule?: Maybe<Array<ITimeSlot>>;
  scheduleEndTime?: Maybe<Scalars['Int']>;
  scheduleStartTime?: Maybe<Scalars['Int']>;
  vehicleSelectionMakes: Array<VehicleMakeSelectionEntry>;
  vehicleSelectionModels: Array<VehicleModelSelectionEntry>;
  vehicleSelectionTrims: Array<VehicleTrimSelectionEntry>;
  vehicleSelectionYears: Array<VehicleYearSelectionEntry>;
};


export type QueryProviderArgs = {
  id: Scalars['String'];
};


export type QueryProviderBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryProviderServiceArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryProviderServiceItemsArgs = {
  filters: ProviderServiceItemFilters;
};


export type QueryProviderServicesArgs = {
  filters?: InputMaybe<ExpertProviderServicesFilters>;
};


export type QueryReservationArgs = {
  id: Scalars['String'];
};


export type QueryReservationGetByChannelArgs = {
  channel_id: Scalars['String'];
};


export type QueryReservationsArgs = {
  filters?: InputMaybe<ReservationsFilter>;
};


export type QueryScheduleArgs = {
  filters?: InputMaybe<ProviderScheduleFilters>;
};


export type QueryVehicleSelectionMakesArgs = {
  year: Scalars['String'];
};


export type QueryVehicleSelectionModelsArgs = {
  make: Scalars['String'];
  year: Scalars['String'];
};


export type QueryVehicleSelectionTrimsArgs = {
  make: Scalars['String'];
  model: Scalars['String'];
  year: Scalars['String'];
};

export type Reservation = {
  __typename?: 'Reservation';
  actions?: Maybe<Array<ReservationAction>>;
  availability_id?: Maybe<Scalars['ID']>;
  cancellation?: Maybe<ReservationCancellationDetails>;
  /** The changes made to the reservation. Unless the user is the operator, the changes are filtered to only show the changes made by the user or pending approval by the user. */
  changes?: Maybe<Array<ReservationChange>>;
  client?: Maybe<User>;
  completed_at?: Maybe<Scalars['Int']>;
  confirmation_code?: Maybe<Scalars['String']>;
  discount_selections?: Maybe<Array<DiscountSelection>>;
  duration_min?: Maybe<Scalars['Int']>;
  expert_first_name?: Maybe<Scalars['String']>;
  expert_id?: Maybe<Scalars['String']>;
  finalized_at?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  in_progress_estimated_end_time?: Maybe<Scalars['Int']>;
  in_progress_start_time?: Maybe<Scalars['Int']>;
  input_requirements?: Maybe<Array<InputRequirements>>;
  invoice?: Maybe<Invoice>;
  is_preview?: Maybe<Scalars['Boolean']>;
  is_stationary?: Maybe<Scalars['Boolean']>;
  is_weekly?: Maybe<Scalars['Boolean']>;
  item?: Maybe<ReservationItem>;
  items?: Maybe<Array<IReservationItem>>;
  location?: Maybe<UserLocation>;
  payment_transactions?: Maybe<Array<PaymentTransaction>>;
  preview?: Maybe<Reservation>;
  providerPayment?: Maybe<ProviderReservationPayment>;
  provider_availabilities?: Maybe<Array<ProviderAvailability>>;
  provider_id?: Maybe<Scalars['String']>;
  service_category?: Maybe<ProviderServiceCategory>;
  status?: Maybe<ReservationStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  /** The time slot of the reservation. If a value was not provided at creation, the start/end times will be set to 0. */
  time_slot?: Maybe<TimeSlot>;
  user_first_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};


export type ReservationItemArgs = {
  id: Scalars['String'];
};


export type ReservationProvider_AvailabilitiesArgs = {
  input?: InputMaybe<ReservationProviderAvailabilitiesGetInput>;
};

export type ReservationAction = {
  __typename?: 'ReservationAction';
  disabled?: Maybe<Scalars['Boolean']>;
  disabled_message?: Maybe<Scalars['String']>;
  type?: Maybe<ReservationActionType>;
};

export enum ReservationActionType {
  CompleteItem = 'COMPLETE_ITEM',
  Confirm = 'CONFIRM',
  Decline = 'DECLINE',
  Reschedule = 'RESCHEDULE',
  StartItem = 'START_ITEM',
  StartRoute = 'START_ROUTE'
}

export type ReservationCancellationDetails = {
  __typename?: 'ReservationCancellationDetails';
  can_cancel?: Maybe<Scalars['Boolean']>;
  cancellation_fee?: Maybe<Scalars['Int']>;
};

export type ReservationChange = {
  __typename?: 'ReservationChange';
  approved_by?: Maybe<UserActionRecord>;
  created_by?: Maybe<UserActionRecord>;
  id: Scalars['String'];
  item?: Maybe<IReservationItem>;
  pending_approval_by?: Maybe<UserActionRecord>;
  status?: Maybe<ReservationChangeStatus>;
  type: ReservationChangeType;
};

export type ReservationChangeInput = {
  item?: InputMaybe<ReservationItemSelectionInput>;
  item_id?: InputMaybe<Scalars['String']>;
  type: ReservationChangeType;
};

/** The status of the reservation change */
export enum ReservationChangeStatus {
  Applied = 'APPLIED',
  Confirmed = 'CONFIRMED',
  None = 'NONE',
  PendingCardPayment = 'PENDING_CARD_PAYMENT',
  Rejected = 'REJECTED'
}

/** The type of reservation change */
export enum ReservationChangeType {
  AddItem = 'ADD_ITEM',
  DeleteItem = 'DELETE_ITEM',
  UpdateItem = 'UPDATE_ITEM'
}

export type ReservationCompleteItemInput = {
  item_id: Scalars['String'];
};

export type ReservationConfirmResponse = {
  __typename?: 'ReservationConfirmResponse';
  level?: Maybe<MessageLevel>;
  message?: Maybe<Scalars['String']>;
  reservation?: Maybe<Reservation>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ReservationCreateInput = {
  expert_id?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<ReservationItemSelectionInput>>;
  location_id?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['String']>;
  time_slot?: InputMaybe<StartTimeFutureTimeSlotInput>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type ReservationDeclineInput = {
  /** An amount to charge the customer as a cancelation fee in cents. */
  charge_cancelation_fee_amount?: InputMaybe<Scalars['Int']>;
  /** Whether to not update or cancel the invoice, but charge it as is. */
  charge_full_amount?: InputMaybe<Scalars['Boolean']>;
  /** When the mutation returns a CONFLICT error, a confirmation might be required. */
  confirm?: InputMaybe<Scalars['Boolean']>;
};

export type ReservationItem = IReservationItem & {
  __typename?: 'ReservationItem';
  actions?: Maybe<Array<ReservationAction>>;
  completed_at?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  duration_min?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  in_progress_end_time?: Maybe<Scalars['Int']>;
  in_progress_start_time?: Maybe<Scalars['Int']>;
  is_preview?: Maybe<Scalars['Boolean']>;
  provider_services?: Maybe<Array<ProviderServiceSelection>>;
  status?: Maybe<ReservationItemStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  vehicle?: Maybe<Vehicle>;
};

export type ReservationItemSelectionInput = {
  /** The services and options to be applied to the reservation item. If the array is emp provided and id matches an existing item, the existing item's services and options will be used. */
  provider_service_selections?: InputMaybe<Array<ProviderServiceSelectionInput>>;
  vehicle_id?: InputMaybe<Scalars['String']>;
};

/** Reservation item status */
export enum ReservationItemStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type ReservationPaymentMethodInput = {
  payment_method_id?: InputMaybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type ReservationPaymentMethods = {
  __typename?: 'ReservationPaymentMethods';
  methods?: Maybe<Array<PaymentMethod>>;
  types?: Maybe<Array<PaymentMethodType>>;
};

export type ReservationProviderAvailabilitiesGetInput = {
  /** The location of the user. If not provided, the location of the reservation will be used. If not provided, the location based on ip. */
  location?: InputMaybe<GeoLocationInput>;
  /** The provider id to search for availabilities. If not provided, the provider id of the reservation will be used. */
  provider_id?: InputMaybe<Scalars['String']>;
  /** The radius in meters to search for providers. Defaults to 30 miles up to 50 meters will be used, while prioritizing closer availabilities. */
  radius_meters?: InputMaybe<Scalars['Float']>;
  /** The start time of the query in seconds since epoch. If not provider, the reservation's start time will be used or the current time if the reservation is not provided. */
  time_slot_start_time?: InputMaybe<Scalars['Int']>;
};

export type ReservationScheduleInput = {
  payment_method?: InputMaybe<ReservationPaymentMethodInput>;
};

export type ReservationScheduleResponse = {
  __typename?: 'ReservationScheduleResponse';
  level?: Maybe<MessageLevel>;
  message?: Maybe<Scalars['String']>;
  reservation?: Maybe<Reservation>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ReservationStartItemInput = {
  item_id: Scalars['String'];
};

/** Reservation status */
export enum ReservationStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Draft = 'DRAFT',
  EnRoute = 'EN_ROUTE',
  Finalized = 'FINALIZED',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED',
  Unassigned = 'UNASSIGNED',
  Unscheduled = 'UNSCHEDULED'
}

export enum ReservationStatusCategory {
  Draft = 'DRAFT',
  Past = 'PAST',
  Upcoming = 'UPCOMING'
}

export type ReservationTimeSlot = ITimeSlot & {
  __typename?: 'ReservationTimeSlot';
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset?: Maybe<Scalars['Int']>;
  /** End time in epoch seconds */
  end_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset?: Maybe<Scalars['Int']>;
  reservation?: Maybe<Reservation>;
  /** Start time in epoch seconds */
  start_time?: Maybe<Scalars['Int']>;
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id?: Maybe<Scalars['String']>;
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: Maybe<Scalars['String']>;
  type?: Maybe<TimeSlotType>;
};

export type ReservationUpdateEstimatedCompletionInput = {
  item_id: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  timestamp: Scalars['Int'];
};

export type ReservationUpdateInput = {
  location_id?: InputMaybe<Scalars['String']>;
  time_slot?: InputMaybe<StartTimeFutureTimeSlotInput>;
};

export type ReservationsFilter = {
  last_start_time?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort_direction?: InputMaybe<SortDirection>;
  status_category?: InputMaybe<ReservationStatusCategory>;
};

export type SaveProviderInput = {
  business_settings?: InputMaybe<ProviderBusinessSettingsInput>;
  policies?: InputMaybe<ProviderPoliciesInput>;
  public_profile?: InputMaybe<ProviderPublicProfileInput>;
};

export type ScheduledPayment = {
  __typename?: 'ScheduledPayment';
  /** The amount due from the reservation's total amount. Value in cents. */
  amount_due?: Maybe<Scalars['Int']>;
  /** The epoch time in seconds when the amount is due. 0 if the invoice is not due. -1 if the invoice is due immediately. */
  amount_due_at?: Maybe<Scalars['Int']>;
  /** The status of the reservation when the payment is due. */
  amount_due_when_status?: Maybe<ReservationStatus>;
  id: Scalars['ID'];
};

/** How to sort the results */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StartTimeFutureTimeSlotInput = {
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset: Scalars['Int'];
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset: Scalars['Int'];
  /** Start time in epoch seconds */
  start_time: Scalars['Int'];
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id: Scalars['String'];
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: InputMaybe<Scalars['String']>;
};

export type TimeSlot = ITimeSlot & {
  __typename?: 'TimeSlot';
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset?: Maybe<Scalars['Int']>;
  /** End time in epoch seconds */
  end_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset?: Maybe<Scalars['Int']>;
  /** Start time in epoch seconds */
  start_time?: Maybe<Scalars['Int']>;
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id?: Maybe<Scalars['String']>;
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: Maybe<Scalars['String']>;
  type?: Maybe<TimeSlotType>;
};

/** Time slot type */
export enum TimeSlotType {
  Break = 'BREAK',
  Reservation = 'RESERVATION'
}

export type UpdatePhoneNumberInput = {
  channel?: InputMaybe<VerificationChannel>;
  phone_number: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  country?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fleet?: Maybe<Fleet>;
  group: UserGroup;
  id: Scalars['ID'];
  is_anonymous?: Maybe<Scalars['Boolean']>;
  is_expert?: Maybe<Scalars['Boolean']>;
  is_operator?: Maybe<Scalars['Boolean']>;
  phone_number?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  twilio_user_id?: Maybe<Scalars['String']>;
  /** @deprecated Use id instead. */
  uid: Scalars['ID'];
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserActionRecord = {
  __typename?: 'UserActionRecord';
  timestamp: Scalars['Int'];
  user: User;
};

/** The user group */
export enum UserGroup {
  Customer = 'CUSTOMER',
  Expert = 'EXPERT',
  Internal = 'INTERNAL',
  Operator = 'OPERATOR'
}

export type UserLocation = {
  __typename?: 'UserLocation';
  access_to_power?: Maybe<Scalars['Boolean']>;
  access_to_water?: Maybe<Scalars['Boolean']>;
  additional_instructions?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  gplace_id?: Maybe<Scalars['String']>;
  gplaces_photo_reference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['Float']>;
  location_type?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['ID']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  license_plate: Scalars['String'];
  license_plate_state: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  size: VehicleSize;
  trim?: Maybe<Scalars['String']>;
  /** User's ID */
  uid: Scalars['ID'];
  year: Scalars['String'];
};

export type VehicleMakeSelectionEntry = {
  __typename?: 'VehicleMakeSelectionEntry';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type VehicleModelSelectionEntry = {
  __typename?: 'VehicleModelSelectionEntry';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Size of the vehicle */
export enum VehicleSize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  Unknown = 'UNKNOWN',
  XLarge = 'X_LARGE'
}

export type VehicleTrimSelectionEntry = {
  __typename?: 'VehicleTrimSelectionEntry';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Vehicle add-on dirt level */
export enum VehicleWashAddOnDirtLevel {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Na = 'NA',
  None = 'NONE'
}

export type VehicleWashAddOnDisplayProperties = IProviderServiceDisplayProperties & {
  __typename?: 'VehicleWashAddOnDisplayProperties';
  additional_info?: Maybe<Scalars['String']>;
  /** Whether to show a none option for the service during customer selection */
  allow_none_option?: Maybe<Scalars['Boolean']>;
  /** @deprecated use subtitle instead */
  description?: Maybe<Scalars['String']>;
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  /** Whether or not the user is required to select an option. This will only be honored if the service has more than one option. */
  option_selection_required?: Maybe<Scalars['Boolean']>;
  prompt?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  /** The starting price in cents */
  starting_price?: Maybe<Scalars['Int']>;
  sub_category?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type VehicleWashAddOnItem = {
  __typename?: 'VehicleWashAddOnItem';
  dirty_level: VehicleWashAddOnDirtLevel;
  identifier: VehicleWashAddonIdentifier;
};

/** Identifier for wash add-ons */
export enum VehicleWashAddonIdentifier {
  CeramicMaintenance = 'CERAMIC_MAINTENANCE',
  ChildSeat = 'CHILD_SEAT',
  ClayBar = 'CLAY_BAR',
  CleanLeatherSeats = 'CLEAN_LEATHER_SEATS',
  HeadlightRestoration = 'HEADLIGHT_RESTORATION',
  PetHairRemoval = 'PET_HAIR_REMOVAL',
  PlasticVinylRestorer = 'PLASTIC_VINYL_RESTORER',
  StainRemoval = 'STAIN_REMOVAL',
  WindshieldWaterRepellent = 'WINDSHIELD_WATER_REPELLENT'
}

export type VehicleWashPackageDisplayProperties = IProviderServiceDisplayProperties & {
  __typename?: 'VehicleWashPackageDisplayProperties';
  additional_info?: Maybe<Scalars['String']>;
  /** Whether to show a none option for the service during customer selection */
  allow_none_option?: Maybe<Scalars['Boolean']>;
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  included_add_ons?: Maybe<Array<VehicleWashAddOnItem>>;
  notes: Array<Scalars['String']>;
  /** Whether or not the user is required to select an option. This will only be honored if the service has more than one option. */
  option_selection_required?: Maybe<Scalars['Boolean']>;
  prompt?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  /** The starting price in cents */
  starting_price?: Maybe<Scalars['Int']>;
  sub_category?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type VehicleWashPackageItem = IProviderServiceItem & {
  __typename?: 'VehicleWashPackageItem';
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  included_comment?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  service_category?: Maybe<ProviderServiceCategory>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type VehicleYearSelectionEntry = {
  __typename?: 'VehicleYearSelectionEntry';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** The verification channels currently supported */
export enum VerificationChannel {
  Call = 'call',
  Email = 'email',
  Sms = 'sms',
  Sna = 'sna',
  Whatsapp = 'whatsapp'
}
